@import '../../Utils/styles/colors';

.root-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    position: relative;

    .MenuAndMainWrapper {
        display: flex;
        flex-direction: row;
        align-items: start;
        flex: 1;
        height: 100%;
        overflow: hidden;
        z-index: 100;

    }
}