@import "../../../../Utils/styles/colors.scss";
.CreateEditNewDocSignModal{
    width: 600px;
    .notifyUserWrapper{
        width: 80%;
    }
    .messagingTypeWrapper{
        width: 80%;
    }

    .selectedChip{
        background-color: $menu-button;
        color: $menu-text-color;
        .MuiSvgIcon-root{
            color: $menu-text-color;
        }
        .MuiChip-label{
            margin-top: 2px;
        }
        &.missingFinishDate{
            background-color: $themeRed;
            color: $headerColor;
            .MuiSvgIcon-root{
                color: $headerColor;
            }

        }
    }
    .inputsContainer{
        width: 80%;
        .newDocSignInput{
            margin-top: 12px;
        }
        .select-box{
            width: 100%;
            margin-top: 12px;
           .MuiFormControl-root{
                width: 100%;
           }
        }
        .typeWrapper{
            &.row{
                margin-bottom: 0;
            }
        }
    }
    .linkUrlError{
        color: red;
        font-size: 12px;
        margin-top: 4px;
    }
    .uploadFileContainer{
        width: 80%;
        height: 100px;
        margin-top: 12px;
    }
    .organizationChipsWrapper{
        width: 80%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 12px;
        .organizationChip{
            margin-left: 12px;
            margin-bottom: 12px;
        }
    }
}