@import '../../Utils/styles/colors';

.AppSideMenu {
  width: 180px;
  height: 100%;
  background-color: $menu-background;
  color: $menu-text-color;
  position: relative;
  z-index: 110;
  text-decoration: unset;
  transition: width 700ms, overflow 700ms;


  .AppSideMenuHeaderTitle {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;

    .UserNameAndMenu {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  .AppSideMenuHeader {
    display: flex;
    flex-direction: column;
    align-items: center;

    .AppSideMenuHeaderLogoWrapper {
      margin-top: 20px;
      margin-bottom: 12px;

      .AppSideMenuHeaderLogo {
        width: 100px;
        height: 100px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 50%;
        transition: background-color 700ms;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .AppSideMenuFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    a {
      text-decoration: none;
      color: $menu-text-color;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;

      .buttonIcon {
        margin-right: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .buttonText {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
      }
    }

  }

  .AppSideMenuButtons {

    .AppSideMenuButton {
      height: 66px;
      margin-bottom: 16px;
      margin-left: -30px;
      background-color: $menu-button;
      color: $menu-text-color;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-right: 8px;
      font-size: 24px;
      font-weight: 600;
      text-decoration: none !important;
      .ltr &{
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        padding-left: 8px;
        padding-right: 0;
        margin-left: 0;
        margin-right: -30px;
      }
      &.selected {
        background-color: $menu-button-selected !important;
        margin-left: -66px;
        .ltr &{
            margin-right: -66px;
            margin-left: 0;
        }
      }

      .buttonText {
        transition: width 400ms;
      }

      .buttonIcon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 66px;

        img {
          width: 100%;
        }
      }

      &:hover {
        background-color: $menu-button-selected;
        margin-left: -66px;
        .ltr &{
            margin-right: -66px;
            margin-left: 0;
        }
      }

      transition: margin-left 700ms, margin-right 700ms, font-size 800ms;
    }

  }
}

@mixin mobileMenu {
  .hamburgerMenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-left: 4px;
    font-size: 20px;
  }
  .AppSideMenu {
    width: 100px;

    .AppSideMenuHeaderTitle {
      font-size: 16px;
      height: 46px;
      transition: opacity 700ms;
      overflow: hidden;
    }

    .AppSideMenuHeader {
      .AppSideMenuHeaderLogoWrapper {
        height: 60px;

        .AppSideMenuHeaderLogo {
          width: 60px;
          height: 60px;
          transition: height 700ms, width 700ms;
        }
      }
    }


    &.closed {
      width: 42px;

      .AppSideMenuFooter {
        .AppSideMenuFooterButton {

          a {
            .buttonIcon {
              margin-right: 0;
            }
          }

          .buttonText {
            display: none;
          }
        }
      }

      .hamburgerMenu {
        padding-left: 0px;
        justify-content: center;
      }

      .AppSideMenuHeader {
        .AppSideMenuHeaderLogo {
          //background-color: transparent;
          height: 30px;
          width: 30px;

          img {
            width: 30px;
            height: 50px;
          }
        }
      }

      .AppSideMenuHeaderTitle {
        opacity: 0;
      }

      .AppSideMenuButtons {
        .AppSideMenuButton {
          width: 100%;
          margin-left: 0;
          padding-right: 0;
          font-size: 0;

          .buttonText {
            width: 0;
          }

          &.selected {
            margin-left: 0px;
          }

          .buttonIcon {
            width: 40px;

            img {
              width: 100%;
            }
          }

          &:hover {
            margin-left: 0px;
          }

        }
      }
    }

    .AppSideMenuButtons {
      .AppSideMenuButton {
        height: 40px;
        margin-bottom: 12px;
        margin-left: -36px;
        font-size: 16px;
        font-weight: 600;

        &.selected {
          margin-left: -52px;
        }

        .buttonIcon {
          width: 40px;

          img {
            width: 100%;
          }
        }

        &:hover {
          margin-left: -36px;
          background-color: $menu-button;
        }

      }
    }
  }
}

@media (max-width: 800px) {
  @include mobileMenu;
}

@media (max-height: 650px) {
  .AppSideMenu {
    zoom: 70%
  }

}

