@import "../../Utils/styles/colors";

.MuiTablePagination-actions {
    //display: flex;
    //flex-direction: row-reverse;
    //justify-content: revert;
}
.MuiDataGrid-main{
    .MuiDataGrid-columnHeaders{
        background-color: $buttonGoldColor;
        color: $headerColor;
        .MuiCheckbox-root.MuiButtonBase-root{
            //.MuiCheckbox-indeterminate{
            color: $headerColor
            //}
        }
    }
    .MuiDataGrid-virtualScrollerRenderZone{
        .MuiCheckbox-root.MuiButtonBase-root{
            color: $buttonGoldColor;
            &.Mui-disabled{
                color: transparentize($button-color, 0.7);
            }

        }
    }
}



.DataGrid{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .MuiDataGrid-cell{
        font-family: "Heebo", serif;
    }
    .MuiDataGrid-overlayWrapper{
        min-height: 16px;
        div{
            min-height: 16px;
            overflow-y: hidden;
        }
    }
    .filterContainer{
        margin-bottom: 12px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .searchFilterContainer{
            width: 240px;
        }
        .filter{
            margin-left: 12px
        }
        button{
            margin-bottom: 8px;
            margin-left: 12px;
        }
        a{
            &.a-button{
                margin-bottom: 8px;
                display: flex;
                flex-direction: row;
                align-items: center;
                text-decoration: none;
                color: $page-title;
                font-size: 16px;
                border: 1px solid $page-title;
                padding: 12px 24px;
                border-radius: 30px;
                transition: background-color 0.3s;
                margin-left: 12px ;
                height: 40px;
                &:hover {
                    background-color: rgba(199, 195, 195, 0.44);

                }
                .iconWrapper{
                    margin-left: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }


        }
    }
    .tableContainer{
        //height: 100%;
        width: 100%;
        min-height: 0;
    }


}