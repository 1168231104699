@import "../../../../Utils/styles/colors.scss";
.AddOrganizationsModal{
    width: 460px;
    button{
        margin-top: 12px;
    }
    .instructions{
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 600;
        color : $page-title;
    }
}