@import "../../../Utils/styles/colors";

.SignDocStats{
    padding-bottom: 20px;
    .statsWidgetsContainers{
        margin-bottom: 50px;
    }
    .numberWidgetsRow{
        height: 170px;
        padding: 12px;
        justify-content: flex-start;
        .counter{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-family: Heebo;
            font-weight: bold;
            font-size: 3.6em;
        }
    }
    .widgetRow{
        height: 292px;
        padding: 12px;
        overflow: hidden;
    }
    .filtersRow{
        display: flex;
        justify-content: flex-start;
        margin-bottom: 12px;
        .searchFilter{
            margin-left: 12px;
        }
        .legend{
            margin-right: 20px;
            display: flex;
            align-items: center;
        }
    }

}

