@import "../../../Utils/styles/colors";
.UserRowSignDocsMenu{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .cellCourseProgress{

        width: 100%;

        &.hidden{
            display: none;
        }
       .progressText{
           font-size: 16px;
           font-weight: 700;
           &.notStarted{
               color: $not-started-color;
           }
           &.inProgress{
               color: $in-progress-color;
           }
           &.completed{
               color: $completed-color;
           }
       }
        .progressDate{
            font-size: 12px;
            font-weight: 700;
            color: $subTitle;
        }
    }
    .userRowButtons{
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
        opacity: 1;
        &.hidden{
            visibility: hidden;
            opacity: 0;
        }
        .cellButtonsWrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            button{
                width: 80px;
                min-width: 80px;
                height: 30px;
                font-size: 12px;
                margin-left: 5px;
            }
            &.singleVal{
                button{
                    width: 130px;
                    min-width: 130px;
                }
            }
        }
        button{
            width: 130px;
            height: 30px;
            font-size: 12px;
        }
        transition: opacity 0.5s;
    }
}
