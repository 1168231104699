@import '../../../Utils/styles/colors';

.SelectFinishDateForOrgModal{
    width: 600px;

    .dateAndTZWrapper{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .timezone{
            margin-right: 12px;
        }
    }
}