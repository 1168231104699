@import '../../Utils/styles/colors';
.MenuPage{
    width: 100%;
    overflow-y: auto;
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
    .PageSecondaryTitle{
        padding-bottom: 14px;
        margin-bottom: 12px;
        font-size: 30px;
        color: $page-title;
        font-weight: bold;
        //text-align: center;
        //border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
    }
}

.menuInnerPage{
    padding-right: 80px;
    padding-bottom: 60px;
    padding-left: 80px;
    width: 100%;
    flex-grow: 1;
    min-height: 0;
}

@media (max-width: 800px) {
    .MenuPage {
        .menuInnerPage {
            padding-right: 0px;
            padding-left: 0;
            .PageSecondaryTitle{
                text-align: center;
                padding: 0 12px 0 12px;
            }
        }
    }
}