@import "../../Utils/styles/colors";

.faq-component {
    .MuiCollapse-root {

    }

    .MuiButtonBase-root {
        background-color: $menu-button-selected;
        border-radius: 30px;
        color: $menu-text-color;

    }

    .MuiAccordion-root {
        margin-bottom: 8px;
        border-radius: 12px;
        font-size: 18px;
        font-weight: bold;
    }

    .MuiAccordionDetails-root {
        padding-top: 16px;
    }

    .MuiPaper-rounded::before {
        display: none !important;
    }
    .MuiSvgIcon-root{
        color: white;
        font-size: 30px;
    }

    .MuiAccordion-gutters{
        background-color: $text-background;
        border-bottom-right-radius: 30px !important;
        border-bottom-left-radius: 30px !important;
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;
        //&:last-of-type{
        //    border-bottom-right-radius: 12px !important;
        //    border-bottom-left-radius: 12px !important;
        //}
    }
    //.Mui-expanded{
    //    min-height: 46px;
    //}
}