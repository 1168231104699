@import '../../Utils/styles/colors';

.SelectFinishDateForUserCourseModal{
    width: 600px;
    .datePickerWrapper{
        margin-bottom: 20px ;
        .message{
            margin-top: 12px;
        }
    }
}
