.SignDocFilter{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 68px;
    margin-top: 4px;
    .disabledNotice{
        height: 8px;
        margin-right: 12px;
        width: 100%;
    }
}