@import '../../../Utils/styles/colors';

.editCourseModal {
    width: 700px;
    .courseDetailsWrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        .courseCardWrapper{
            width: 300px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 18px;
        }
        .editFieldsWrapper{
            flex-grow: 1;
            .editField{
                margin-bottom: 16px;
            }
        }

    }
    .courseOrganizationMngmnt{
        .courseOrganizationsChips {
            overflow-y: auto;
            display: flex;
            flex-wrap: wrap;
            flex-flow: row wrap;
            padding-top: 24px;

            .courseChip {
                margin: 6px;
            }
        }
    }

}

.horizontalDivider {
    //width: calc(100% - 60px);
    margin: 30px 30px 30px 0;
    height: 1px;
    background-color: $subTitle;
}