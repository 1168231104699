.EditNote {
    display: flex;
    flex-direction: column;

    .noteEditFields {
        margin-top: 12px;
    }

    .noteWithOrgs {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;


        .orgNoteWrapper {

        }

        .EditRightPane {
            display: flex;
            flex-direction: column;
            height: 100%;
            flex: 1;
        }

        .noteChips {
            height: 50%;
            overflow-y: auto;
            display: flex;
            flex-wrap: wrap;
            flex-flow: row wrap;
            padding-top: 24px;

            .noteChip {
                margin: 6px;
            }
        }

        .editTitle {
            display: flex;
            flex-direction: column;
            margin-bottom: 12px;
        }

        .noteCount {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: 12px;
        }

    }

    .saveContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 12px;
        width: 100%;
        button{
            margin-right: 12px
        }
    }
}

