@import '../../../Utils/styles/colors';
.MessageTypeSelectionButtons{
    .messageTypeWrapper{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .messageTypeButton{
            width: 160px;
            height: 60px;
            border: 1px solid $subTitle;
            border-radius: 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
            font-size: 16px;
            cursor: pointer;
            transition: all 0.3s;
            margin-left: 12px;
            &:last-of-type{
                margin-left: 0;
            }
            &:hover{
                background-color: transparentize($menu-button, 0.7 );
                border-color: $page-title;
            }
            &.selected{
                background-color: $menu-button;
                border-color: $menu-button;
                color: $menu-text-color;
            }
            .MuiSvgIcon-root{
                font-size: 32px;
            }
            .messageTypeText{
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-right: 12px;
            }
            .messageTypeIcon{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

    }
}