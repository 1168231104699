.Reminders{
    padding-top: 20px;
    .remindersInfoAndButton{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .remindersButton{
          margin-left: 12px;
        }
        .remindersInfo{
            width: 700px
        }
    }
    .reminderAutomations{
        margin-top: 20px;
    }
}