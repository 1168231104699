@import "../../Utils/styles/colors";
.UsersPage {
    height: 100%;
    width: 100%;
    .SelectAndButtonsWrapper {
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .OrgSelectWrapper{
            width: 300px;
            margin-left: 12px;
        }
    }
}