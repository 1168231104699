.OrganizationRowMenu{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .valueToPresent{
        width: 300px;
    }
    .userRowButtons{
       opacity: 1;
        height: 100%;
        margin-left: 20px;
        &.hidden{
            visibility: hidden;
            opacity: 0;
        }
        button{
            width: 130px;
            height: 30px;
            font-size: 12px;
        }
        transition: opacity 0.5s;
    }
}