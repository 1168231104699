@import "../../Utils/styles/colors";
.RoutingTabs, .Tabs{
    margin-top: 12px;
    .MuiTabs-indicator{
        background-color: $menu-item-text;
    }
    .MuiTabs-root{
        //background-color: #efb7132b;
        background-color: #d19c0012;
        border-radius: 10px;
        padding: 0 6px 6px 6px;
        //background gradient from center to opacity on sides
        background: linear-gradient(to right, transparentize($tabsGradientBackgroundBase, 0.95), transparentize($tabsGradientBackgroundBase, 0.8), transparentize($tabsGradientBackgroundBase, 0.8), transparentize($tabsGradientBackgroundBase, 0.95));
    }
    .routeContent{
        margin-top: 20px;
    }
}

//mobile
@media (max-width: 800px) {
    .RoutingTabs{
        .MuiTabs-root{
            padding: 0 6px 6px 6px;
            .MuiButtonBase-root{
                font-size: 18px;
                &.MuiTabScrollButton-root{
                    padding-top: 5px;
                }
            }

        }
    }
}
