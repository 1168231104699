@import "../../../Utils/styles/colors";

.SignDocCard {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 260px;
    height: 360px;
    &.doNotForceCompletion{
        height: 300px;
        .signDocCardContent {
            height: 240px;
        }
    }
    .signDocCardImage {
        position: absolute;
        top: 0;
        width: 120px;
        height: 120px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        &.round{
            border-radius: 50%;
            img {
                border-radius: 50%;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .signDocCardContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        padding-top: 50px;
        height: 300px;
        width: 100%;
        background-color: $lightGrey;
        border-radius: 50px;
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;
        .signDocDescription{
            padding-left: 14px;
            padding-right: 14px;
            text-align: center;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 8px;
            height: 40px;
            min-height: 40px;
        }
    }
    .signDocProgressWrapper{
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .signDocTitle {
        font-weight: 800;
        font-size: 27px;
        color: $page-title;
        margin-top: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        padding-right: 6px;
        padding-left: 6px;
        &.small{
            font-size: 22px;
            margin-top: 16px;
            margin-bottom: 2px;
        }
    }

    .signDocButton {
        text-decoration: none;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;

        button{
            width: 100%;
        }
    }

    .signDocEndDate {
        margin-top: 10px;
        font-family: Heebo;
        font-size: 18px;
        font-weight: 700;

        &.completed {
            color: $greyText;
        }

        &.notStarted {
            color: $greyText;
        }

        &.inProgress {
            color: $greyText;
        }
    }

}