.SACreateEditUserModal{
    width: 600px;
    .inputsWrapper{
        margin-top: 12px;
        .form-input{
            margin-bottom: 16px;
        }
    }
    .selectWrapper{
        margin-bottom: 16px;
        width: 100%;
    }
}