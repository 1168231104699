.SuperAdminEditFFModal{
    width: 600px;
    .ffGroup{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        .ffSubGroup{
            margin-right: 50px;
            display: flex;
            flex-direction: column;
        }
    }
}