@import "../../../Utils/styles/colors";
.SMSQuota {
    border-radius: 40px;
    width: 100%;
    background-color: $button-color;
    color: $menu-text-color;
    height: 120px;
    position: relative;
    .bell-icon{
        position: absolute;
        right: 20px;
        top: 26px;
        img{
            width: 60px;
        }
    }
    .SMSQuota__header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height:46px;
    }
    .SMSQuota__content__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 6px;
        .SMSQuota__content__item__title {
            font-size: 20px;
            font-weight: 500;
            margin-left: 12px;
        }

        .SMSQuota__content__item__value {
            font-size: 20px;
            font-weight: bold;
        }
    }
}