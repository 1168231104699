@font-face {
    font-family: Heebo;
    src: url(../../assets/fonts/Heebo-Light.ttf) format('truetype');
    font-weight: 300;
}
@font-face {
    font-family: Heebo;
    src: url(../../assets/fonts/Heebo-Regular.ttf) format('truetype');
    font-weight: 400;
}
@font-face {
    font-family: Heebo;
    src: url(../../assets/fonts/Heebo-Medium.ttf) format('truetype');
    font-weight: 500;
}
@font-face {
    font-family: Heebo;
    src: url(../../assets/fonts/Heebo-Bold.ttf) format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: OpenSansCond;
    src: url(../../assets/fonts/OpenSansHebrewCondensed-Light.ttf) format('truetype');
    font-weight: 300;
}
@font-face {
    font-family: OpenSansCond;
    src: url(../../assets/fonts/OpenSansHebrewCondensed-Regular.ttf) format('truetype');
    font-weight: 400;
}
@font-face {
    font-family: OpenSansCond;
    src: url(../../assets/fonts/OpenSansHebrewCondensed-Bold.ttf) format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: OpenSansCond;
    src: url(../../assets/fonts/OpenSansHebrewCondensed-ExtraBold.ttf) format('truetype');
    font-weight: 800;
}