@import "./Utils/styles/colors";
@import "./Utils/styles/fonts";
@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrewcondensed.css);
.error{
    color: $color-red
}

html{
    height: 100%;
    width: 100%;
    overflow-x: hidden;

}
body{
    height: 100%;
    width: 100%;
    margin: 0;
    padding-right: 0 !important;
    -webkit-filter: blur(0);
    -webkit-transform: translate3d(0,0,0);
    header{
        padding-right: 0 !important;
    }
    &.zoom-small{
        zoom: 63%;
    }
}
#root{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;

}
.root-container{
    height: 100%;
    width: 100%;
}

.main-container{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    width: 100%;
}

::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #dee0ed;
    border-radius: 6px;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #F5F5F5;

}

::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: transparentize($grey1, .9);
    &:hover{
        background-color: transparentize($grey1, .5);
    }
}


html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
*, *::before, *::after {
    box-sizing: inherit;
}
strong, b {
    font-weight: bolder;
}
body {
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    font-size: 0.875rem;
    font-family: OpenSansCond, Heebo, "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #fff;
}
@media print {
    body {
        background-color: #fff;
    }
}

.InternalPage{
    padding: 40px;
    display: flex;
    flex-direction: column;
}


.MuiTableRow-head th{
    background-color: $buttonGoldColor;
    color: $dark;
    font-weight: 700;
    font-size: 16px;
    .MuiTableCell-root{
        color: $dark;
    }
}
