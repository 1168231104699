
.SACustomerQuota{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    .quota{
        margin-left: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }
}

