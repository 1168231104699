.removeOrgCell{
    width: 100%;
}

.removeOrgModal{
    .removeOrgModalTitle{
        font-family: "Heebo";
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 24px;
    }
    .removeOrgModalText{
        font-family: "Heebo";
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 24px;

    }
    .removeOrgModalButtons{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-top: 24px;
    }
}