@import "./../../../../Utils/styles/colors";
.ValuesBar{
    height: 40px;
    width: 100%;
    display: flex;
    //reverse
    flex-direction: row-reverse;
    transition: width 1s;
    border: 1px solid $GraphBorder;
    overflow: hidden;
    border-radius: 24px;
    &.small{
        height: 24px;
        border-radius: 12px;
    }
    .barValue{
        height: 100%;
        //transition
        transition: width 1s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $GraphText;


        &:first-of-type{


        }
        &:last-of-type{
        }
        &.colorYellow{
            background-color: $GraphBackground1;
        }
        &.colorGrey{
            background-color: $GraphBackground2;
        }
        &.colorLightGrey{
            background-color: $GraphBackground3;

        }
        .barValueText{
            font-size: 14px;
            font-weight: 600;
            padding: 10px;
        }
    }
}