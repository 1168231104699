.mui-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .mui-button-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-family: "Heebo";
        &.small{
            .button-icon {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: 4px;
                margin-bottom: 0px;
            }
        }
        .button-icon {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 12px;
            margin-bottom: 3px;
            &.right {
                margin-right: 12px;
                margin-left: 0;
            }
            &.arrow-icon{
                margin-right: -12px;
                margin-left: 12px;
            }
        }
        .button-text{
        }
    }
    &.bar-header-action-button{
        border-width: 3px !important;
        span{
            height: 36px;
        }
    }

}