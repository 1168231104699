$themeLight: #ffffff;
$themeText: #a8dadc;
$themeTitle: #457b9d;
$themeDark: #020518;
$themeRed: #e63946;
$DefaultColor: #f1faee;

$dark: #3f3f3f;
$headerColor: #fff;
$menu-item-text: #efb713;
$menu-item-icon: #efb713;
$button-color: #595a5c;
$button-selected-color: #4f4f50;
$buttonYellowColor: #D19C00;
$buttonGoldColor: #D19C00;
$buttonLightGreyColor: #BDBCBC;

//menu
$menu-background: #efb713;
$menu-button: #d19c00;
$menu-button-selected: #595a5c;
$menu-text-color: #fff;
$greyText: #A8A8A8;
$text-background: #F1F1F1;

//course
$completed-color: #efb713;
$in-progress-color: #595a5c;
$not-started-color: #d7d5d5;

//Graph
$GraphBackground1: #A1D6B2;
$GraphBackground2: #F5D471;
$GraphBackground3: #E3E2E2;
$GraphColor1: #efb713;
$GraphColor2: #595a5c;
$GraphColor3: #d7d5d5;
$GraphBorder: #9B9C9E;
$GraphText: #595a5c;

//page
$page-title: #595a5c;
$subTitle: #a8a8a8;
$lightGrey: rgba(168, 168, 168, 0.16);

$color-light-blue: #79b7ee;
$color-link: #007bff;
$color-success: #69e185;


$color-red: red;
$grey1: grey;

$border-color: #d19c00;
$tabsGradientBackgroundBase: #D19C00;