.ConfirmationModal{
    width: 600px;
    min-height: 300px;
    .message{
        font-size: 18px;
        font-weight: bold;
        text-align: right;
        width: 100%;
        margin-bottom: 20px;
    }
    .messageBody{
        width: 100%;
        font-size: 18px;
        text-align: right;
    }
}