@import '../../../Utils/styles/colors';

.editOrganizationNameModal {
    position: relative;
    width: 500px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-left: 22px;
    .organizationsWrapper{
        width: 100%;
    }
}

