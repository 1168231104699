@import "../../styles/colors";

.ProgressSlider {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    max-height: 50px;
    position: relative;

    .progressTexts{
        width: 100%;
        height: 16px;
        max-height: 16px;
        position: relative;
        font-family: Heebo;
        font-size: 14px;
        font-weight: 700;
        .progressText{
            position: absolute;
            top: -4px;
            //visibility: hidden;
            &.on{
                color: $menu-button;
                //visibility: visible;
            }
            &.startText{
                right: 0;
                .ltr &{
                    left: 0;
                    right: unset;
                }
            }
            &.middleText{
                right: 50%;
                transform: translateX(50%);
                .ltr &{
                    left: 50%;
                    right: unset;
                    transform: translateX(-50%);
                }
            }
            &.endText{
                left: 0;
                .ltr &{
                    right: 0;
                    left: unset;
                }
            }
        }
    }
    .progressBar{
        width: 100%;
        height: 16px;
        max-height: 16px;
        background-color: $subTitle;
        border-radius: 50px;
        position: relative;
        .progressRound{
            position: absolute;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            top: -4px;
            background-color: $subTitle;
            &.on{
                background-color: $menu-button;
            }
            &.startRound{
                right: 0;
                .ltr &{
                    left: 0;
                    right: unset;
                }
            }
            &.middleRound{
                right: 50%;
                transform: translateX(50%);
                .ltr &{
                    left: 50%;
                    right: unset;
                    transform: translateX(-50%);
                }

            }
            &.endRound{
                left: 0;
                .ltr &{
                    right: 0;
                    left: unset;
                }
            }
        }
    }
    .overlayProgressBar{
        height: 16px;
        max-height: 16px;
        background-color: $menu-button;
        border-radius: 50px;
        position: absolute;
        right: 0;
        .ltr &{
            left: 0;
            right: unset;
        }
    }
}