
.alert-dialog-text{
    width: 400px;
}

.alert-modal {
    //background-color: rgba(0,0,0,0.7);
    //width: 100%;
    //height: 100%;
    //position: fixed;
    //top: 0;
    //left: 0;
    //z-index: 1000000000;
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //align-items: center;
    //border-radius: 20px;
    .outside-modal-close-button {
        background-color: #ffffff;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: -40px;
        left: 440px;
        top: -50px;
        cursor: pointer;
    }
    .alert-window {
        width: 800px;
        height: 408px;
        overflow: hidden;
        border-radius: 4px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 8px;
        display: flex;
        .left {
            img {
            }
        }
        .right {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            background-color: white;
            padding-right: 70px;
            width: 100%;
            height: 100%;
            position: relative;
            .title{
                font-size: 25px;
                font-weight: bold;
                text-align: left;
                margin-bottom: 15px;
            }

            .text {
                text-align: left;
                color: black;
                overflow-y: auto;
                max-height: 190px;
                margin-bottom: 25px;
            }
            .buttons {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                padding-left: 100px;
                button {
                    color: white;
                }
            }
        }
    }
}

.errors-list-alert {
    overflow: auto;
    max-height: 250px;
    ul {
        padding-left: 20px;
        li {
            max-width: 370px;
        }
    }
}