
.login-first-page-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex: 1;
    .login-comp-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 550px;
        //padding-right: 90px;
        .login-container{
            margin-top: 0;
        }
    }
    .svg-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 1080px;
        overflow: hidden;
        height: 100%;
    }
}