@import "../../Utils/styles/colors";

.LearningManagement {
    .title {
        padding-bottom: 6px;
        font-size: 28px;
        color: $dark;
        font-weight: 400;
        font-family: Khand, sans-serif;
        border-bottom: 1px solid #afafaf;
    }
    .learningManagementTabWrapper{
        margin-top: 20px;
    }

    .buttonsRow {
        margin-top: 12px;
    }

    .backgroundCover {
        width: 100%;
        height: 100%;
        min-height: 500px;
        background-size: cover;
        position: relative;
        z-index: 100;
    }




}