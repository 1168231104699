@import '../../../Utils/styles/colors';
.DoughnutChartLegend{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    .legendItem{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 20px;
        .ltr &{
            margin-left: 0;
            margin-right: 20px;
        }
        .legendColor{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-left: 10px;
            .ltr &{
                margin-left: 0;
                margin-right: 10px;
            }
            &.colorYellow{
                background-color: $GraphBackground1;
            }
            &.colorGrey{
                background-color: $GraphBackground2;
            }
            &.colorLightGrey{
                background-color: $GraphBackground3;
            }
        }
    }
}