@import "../../Utils/styles/colors";

$rightPanelPaddingLeft: 40px;

.home {
    .homeContent {
        position: relative;
        z-index: 104;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .FAQWrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            width: 60%;

            .FAQ {
                width: 100%;
            }

        }
        .homeNotesRow {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-top: 30px;

            .Notes{
                display: flex;
                flex-direction: row;
                margin-left: 40px;
                .ltr &{
                    margin-left: 0;
                    margin-right: 40px;
                }
            }
            .Chart {
                height: 100%;
            }
        }
    }

    .Chart {
        z-index: 103;
        margin-bottom: 40px;
        .legend{
            margin-bottom: 20px;
            display: flex;
            align-items: center;
        }
    }
}

@media (max-width: 800px) {
    .home {
        .homeContent {

            .FAQWrapper {
                padding-left: 12px;
                padding-right: 12px;
                width: 100%;

                .FAQ {
                }
                .title {

                }
            }
            .homeNotesRow {
                flex-direction: column;
                height: unset;
                .Notes{
                    flex-direction: column;
                    margin-left: 0;
                }
                .Chart {
                }
            }
        }

        .Chart {
        }
    }
}