.AddEditAdminUserModal{
    width: 500px;
    min-height: 400px;
    max-height: 800px;
    .adminSwitchWrapper{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;
        width: 100%;
    }
    .selectUser{
        width: 100%;
        margin-top: 12px;
    }
    .managingOrganizations{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 40px;
    }
}
