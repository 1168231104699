.MyOpenTasks{
    .signDocsTitle{
        margin-top: 40px;
    }
    .openTasksSection{
        //overflow-y: auto;
        .OpenTasksWrapper{
            margin-top: 30px;
            display: flex;
            flex-flow: wrap;
            flex-grow: 1;
            overflow-y: auto;
            .courseWrapper{
                margin: 0 0 30px 30px;
            }
            .signDocWrapper{
                margin: 0 0 30px 30px;
            }
        }
    }
}

@media (max-width: 800px) {
    .MyOpenTasks {
        .title{

        }
        .backgroundCover{

        }
        .openTasksSection{

            .OpenTasksWrapper{
                justify-content: center;
                .signDocWrapper{
                    margin: 0 0 30px 0;
                }
                .courseWrapper{
                    margin: 0 0 30px 0;
                }
            }
        }

    }
}