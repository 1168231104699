.EditOrganization{
    .orgName {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 90px;


        .orgNameTitle {
            font-size: 24px;
            font-weight: 600;
            //color: $title;
            margin-right: 20px;
        }

    }
    .saveContainer{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}

