@import '../../Utils/styles/colors';


.UserIconMenu {
  display: flex;
  align-items: center;
  .selectBoxWrapper {
    border-radius: 6px;
    background-color: $themeLight;
  }
}
.logoutLink{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: $themeDark;
}


