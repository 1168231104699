.select-box{
  .select-label{
    text-align: left;
    width: 100%;
    font-size: 12px;
    margin-bottom: 3px;
  }
  .select-input{
    width: 100%;
    text-align: left;
  }
  label, option{
    font-family: Heebo;
    background-color: white;
    padding-left: 4px
  }
  .MuiInputBase-input{
    font-family: Heebo
  }
}