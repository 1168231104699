@import "../../Utils/styles/colors";
.Note{
    width: 300px;
    height: 300px;
    background-image: url("../../assets/images/stickyNote.svg");
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 62px 42px 42px 42px;
    text-align: center;
    font-size: 20px;
    color: $page-title;
    .noteTitle{
        font-weight: 800;
        height: 60px;
        max-width: 222px;
        word-break: break-word;
    }
    .noteContent{
        margin-top: 20px;
        max-width: 222px;
        word-break: break-word;
        white-space: pre-wrap;
        &.small{
            font-size: 16px;
        }
    }
}