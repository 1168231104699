@import "../../../Utils/styles/colors";
.StatsWidgetWrapper{
    width: 100%;
    height: 100%;
    border: 2px solid $border-color;
    border-radius: 26px;
    box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.2), 0px 3px 8px 3px rgba(0, 0, 0, 0.14), 0px 5px 12px 6px rgba(0, 0, 0, 0.12);
    padding: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    .infoBubble{
        position: absolute;
        top: 12px;
        left: 12px;
    }
    .statsWidgetTitle{
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 16px;
        border-bottom: 1px solid $border-color;
        padding-bottom: 6px;
    }
    .statsWidgetBottomTitle{
        font-size: 22px;
        font-weight: 700;
        text-align: center;
    }
    .widgetContent{
        flex-grow: 1;
        overflow-y: auto;
    }
}