.SignDocsList {

    .signDocumentTitle {
        margin-top: 40px;
    }

    .signDocumentsWrapper {
        display: flex;
        flex-flow: wrap;
        flex-grow: 1;
        margin-top: 30px;

        .signDocumentWrapper {
            margin: 0 0 30px 30px;
        }
    }
}