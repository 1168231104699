.CreateEditUserModal{
    width: 600px;
    .inputsWrapper{
        margin-top: 12px;
        .form-input{
            margin-bottom: 16px;
        }
    }
    .selectWrapper{
        margin-bottom: 16px;
        width: 100%;
        margin-top: 12px;
    }
    .userMessage{
        width: 100%;
        margin-top: 12px;
        color: red
    }
    .userIdentifierWithPrefix{
        width: 44%;
        min-width: 44%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 12px;
        .MuiFormControl-root{
            margin-left: 0;
        }
        .inputPrefix{
            direction: ltr;
            height: 56px;
            //min-width: 80px;
            border-top: 1px solid black;
            border-left: 1px solid black;
            border-bottom: 1px solid black;
            padding-left: 8px;
            padding-right: 8px;
            border-radius: 6px 0 0 6px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-weight: bold;
        }
    }
}