@import '../../../Utils/styles/colors';
.importUsers {
    width: 100%;
    display: flex;
    flex-direction: column;

    .usersContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 12px;
        flex-grow: 1;
        overflow: hidden;
        margin-bottom: 12px;

        .usersValues {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            overflow-y: auto;
        }

        .tableTitles {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 12px;
            font-weight: 600;
            font-size: 18px;

        }

        .userRow {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-size: 16px;

            .error {
                color: red;
                background-color: #f8d7da;
                border-radius: 6px;
                height: 100%;
            }
        }

        .countCol {
            width: 50px;
            margin-left: 8px;
        }

        .firstName {
            width: 120px;
            margin-left: 8px;
        }

        .lastName {
            width: 120px;
            margin-left: 8px;
        }

        .identifier {
            width: 180px;
            margin-left: 8px;
        }

        .phone {
            width: 120px;
            margin-left: 8px;
        }

        .email {
            width: 150px;
            margin-left: 8px;
        }

        .organizations {
            width: 200px;
            margin-left: 8px;
        }




    }
    .errorContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 12px;
    }
    .welcomeMessageSend {
        width: 100%;
        margin-bottom: 12px;
    }

    .selectWrapper {

        width: 100%;

        .selectBoxRowWrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 12px;

            .organizationsSelectWrapper {
                width: 300px;
            }
        }

        .select-input {
            min-width: 300px;
        }

        .mui-button {
            margin-right: 12px;
        }

        .selectTitle {
            margin-bottom: 12px;
            font-weight: 600;
            font-size: 16px;
            display: flex;
            flex-direction: row;
            .selectSubTitle{
                font-size: 14px;
                color: $color-red;
            }
        }
    }

}