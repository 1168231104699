@import "../../../Utils/styles/colors.scss";
.CreateEditCourse{
    width: 900px;
    .upperRow{
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-top: 8px;

    }
    .editCourseContent{
        width: 100%;
        .text2{
            color: red;
            padding-right: 10px;
            padding-left: 10px;

        }
    }
    .saveContainer{
        width: 100%;
        padding: 12px ;
        border-bottom: 1px solid $subTitle;
        margin-bottom: 12px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .courseDetails {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 500px;
        .MuiFormControl-root{
            margin-bottom: 10px;
        }
    }
    .courseZipFile{
        width: 300px;
        height: 122px;
        margin-right: 20px;
    }

    .organizationsTable{
        flex-grow: 1;
        width: 100%;
    }
}