@import "../../Utils/styles/colors";

.PageTitle {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 420px;
    min-height: 420px;
    max-height: 420px;
    &.small{
        height: 300px;
        min-height: 300px;
        max-height: 300px;
    }
    &.minimized{
        height: 200px;
        min-height: 200px;
        max-height: 200px;
        .customerLogo{
            display: none;
        }
    }

    .PageTitleAbsolute {
        position: absolute;
        z-index: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .PageTitleAbsolute {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
    }


    .gradient {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(255, 255, 255, 0.8), rgb(255, 255, 255));
        position: absolute;
        z-index: 2;
    }

    .TitleRow {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        //padding-left: 460px;
        z-index: 100;
        margin-right: 80px;
        height: 100%;
        margin-bottom: 150px;
        width: 100%;
        padding-left: 80px;

        .TitleWrapper {
            margin-left: 100px;
            z-index: 100;

            .TitleText {
                font-size: 66px;
                color: $page-title;
                font-weight: 800;
            }

            .SubtitleText {
                font-size: 40px;
                color: $subTitle;

            }

            .SubtitleBoldText {
                font-size: 40px;
                color: $subTitle;
                font-weight: 700;
                margin-right: 12px;
                .ltr &{
                    margin-right: 0;
                    margin-left: 12px;
                }
            }

        }
    }

    .WeezBeeLogo {
        z-index: 1000;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        width: 220px;
        height: 200px;
        padding-top: 110px;
        padding-right: 50px;
        margin-left: -30px;
        padding-left: 40px;
        margin-top: -130px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .customerLogo {
        position: absolute;
        top: -120px;
        left: calc(50% - 230px);
        //right: 0;
        width: 360px;
        height: 360px;
        border-radius: 50%;
        padding-top: 110px;
        z-index: 110;
        background-color: #fff;
        border: 12px solid $menu-background;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
            max-width: 240px;
            max-height: 176px
        }
    }
}

@media (max-width: 800px) {
    .PageTitle {
        height: 330px;
        min-height: 330px;
        max-height: 330px;
        &.minimized{
            height: 230px;
            min-height: 230px;
            max-height: 230px;
            .customerLogo{
                display: none;
            }
        }
        .PageTitleAbsolute {

        }

        .PageTitleAbsolute {
            img {

            }
        }


        .gradient {

        }

        .TitleRow {
            margin-right: 12px;

            .TitleWrapper {
                margin-left: 20px;
                margin-bottom: 30px;
                .TitleText {
                    font-size: 36px;
                }

                .SubtitleText {
                    font-size: 30px;

                }

                .SubtitleBoldText {
                    font-size: 30px;
                }

            }
        }

        .WeezBeeLogo {
            width: 186px;
            height: 190px;

            img {

            }
        }

        .customerLogo {
            width: 210px;
            height: 240px;
            top: -144px;
            left: 150px;
            z-index: 1000;
            border: 4px solid $menu-background;
            padding-top: 112px;

            img {
                max-width: 74%;
            }
        }
    }
}

.ltr{
    .PageTitle {
        .TitleRow {
            .TitleWrapper{
                margin-left: 0;
                margin-right: 100px;
            }
        }
        .WeezBeeLogo {
            padding-right: 40px;
            margin-left: 0;
            margin-right: -30px;
            padding-left: 50px;
            right: 0;
            left: unset;
        }
    }
}