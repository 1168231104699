
.MultipleHorizontalValueBars{
    width: 100%;
    height: 100%;
    .barWrapper{
        margin-bottom: 12px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: center;
        .barTitle{
            width: 130px;
            min-width: 130px;
            font-size: 14px;
            font-weight: 600;
            margin-right: 10px;
            //text overflow ellipsis
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .barComponent{
            flex-grow: 1;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}