@import '../../../Utils/styles/colors';

.SMSNotificationButton {
    $width: 200px;
    $height: 140px;
    width: $width;
    height: $height;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    cursor: pointer;

    &:hover {
        .rectangle {
            background-color: transparentize($button-color, 0.2);
            //handle shadow on hover
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
        }
    }

    .bell-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 20px;
        left: $width/2 - 20px;

        img {
            height: 54px;
        }
    }

    .rectangle {
        width: $width;
        height: $height - 40px;
        background-color: $button-color;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $menu-text-color;
        font-family: Heebo;
        padding-top: 16px;
        //transition both background color and shadow
        transition: background-color 0.3s, box-shadow 0.3s;
        //add button shadow
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        .title {
            font-size: 22px;
            font-weight: 700;
        }

        .sub-title {
            font-size: 15px;

        }
    }

    &.fullHeight {
        height: 120px;
        .rectangle{
            height: 120px;
            border-radius: 40px;
            padding-top: 40px;
        }
        .bell-icon {
            top: 10px;
        }
    }
}