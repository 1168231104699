@import "../../Utils/styles/colors";
.Settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    .settingsWrapper{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

}