.chartWrapper{
    position: relative;
    width: 300px;
    height: 300px;
    .chartInner{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        width: 124px;
    }
}