
.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 12px;
}

.col-100{
    width: 100%;
    height: 100%;
}
.col-50 {
    width: 50%;
    height: 100%;
    &.margin-left {
        width: calc(50% - 10px);
        margin-left: 10px;
    }
    &.margin-right {
        width: calc(50% - 10px);
        margin-right: 10px;
    }
}
.col-20 {
    width: 20%;
    height: 100%;
    &.margin-left {
        width: calc(20% - 10px);
        margin-left: 10px;
    }
    &.margin-right {
        width: calc(20% - 10px);
        margin-right: 10px;
    }
}


