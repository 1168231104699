
.SuperAdminCourseMgmnt{
    .superAdminCoursesTableWrapper{
        flex-grow: 1;
        width: 100%;
        min-height: 0;
    }
    .buttonsWrapper{
        margin-bottom: 20px;
    }
}