.MySignDocs{
    .signDocsTitle{
        margin-top: 40px;
    }
    .signDocsSection{
        //overflow-y: auto;
        .SignDocsWrapper{
            margin-top: 30px;
            display: flex;
            flex-flow: wrap;
            flex-grow: 1;
            overflow-y: auto;
            .signDocWrapper{
                margin: 0 0 30px 30px;
            }
        }
    }
}

@media (max-width: 800px) {
    .MySignDocs {
        .title{

        }
        .backgroundCover{

        }
        .signDocsSection{

           .SignDocsWrapper{
                justify-content: center;
                .signDocWrapper{
                    margin: 0 0 30px 0;
                }
            }
        }

    }
}