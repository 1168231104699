@import '../../../Utils/styles/colors';
.importUsersModal {
    width: 1200px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    .tabsWrapper{
        width: 100%
    }
    .manyUsersMessage{
        width: 100%;
        height: 60px;
        padding: 4px 12px;
        background-color: transparentize($themeRed, 0.4);
        color: white;
        font-size: 20px;
        border-radius: 12px;
    }

}