@import "../../../Utils/styles/colors";
.ButtonMenu{
    .base-Popper-root{
        z-index: 100;
    }
    .option-in-menu{
        text-decoration: none;
        color: $themeDark
    }
}
