@import "../../../Utils/styles/colors";

.SignDocCard{
    &.MgmntSignDocCard {
        width: 260px;
        height: 280px;
        .signDocCardContent {
            position: relative;
            height: 220px;

            .deleteIcon{
                position: absolute;
                bottom: -5px;
                width: 100%;
                text-align: center;
                font-size: 24px;
                color: $buttonYellowColor;
                visibility: hidden;
                transition: all 0.2s ease-in-out;
                opacity: 0;
                .MuiSvgIcon-root{
                    cursor: pointer;
                }
                &.hover{
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

    }
}
