.login-container{
    width: 330px;
    margin: auto;
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .loginLogo{
        margin-bottom: 20px;
    }

    img{
        &.loginLogo{
            width: 80%;
        }
    }
    .login-form{
        margin-top: 12px;
        width: 100%;
    }
    .login-text-field{
        &.first{
            margin-bottom: 20px;
        }
    }
    .error{
        margin-bottom: 20px;
    }
    .login-btn-wrapper{
        text-align: center;
        margin-top: 12px;
        button{
            width: 100%;
        }
    }
    .login-text{
        margin-top: 34px;
        font-size: 34px;
        font-family: Heebo;
    }
}

.login-modal{
    .login-container{
        margin-top: 0px;
    }
    .login-modal-message{
        font-family: Heebo;
        margin-bottom: 26px;
        font-size: 18px;
        text-align: center;
        font-weight: bold;


    }
}


//mobile
@media (max-width: 768px) {
    .login-container{
        width: 70%;
        margin-top: 0;
        //img{
        //    width: 80%;
        //}
        .login-text{
            margin-top: 20px;
            font-size: 24px;
        }
    }
}
