.change-password-container{
    width: 300px;
    margin: auto;
    margin-top: 200px;
    img{
        width: 100%;
    }
    .login-form{
        margin-top: 12px;
    }
    .login-text-field{
        &.first{
            margin-bottom: 20px;
        }
    }
    .error{
        margin-bottom: 20px;
    }
    .login-btn-wrapper{
        text-align: center;
    }
}
