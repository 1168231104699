.loading-container{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50000;
    &.local{
        position: relative;
    }
    .text-wrapper{
        margin-top: 12px;
        background: rgba(0, 152, 255, 0.91);
        color: white;
        padding: 30px;
        border-radius: 6px;
        font-weight: bold;
        .text-loading{
            @keyframes pulse {
                0% {transform: scale(1);}
                50% {transform: scale(1.1);}
                100% {transform: scale(1);}

            }
            animation-name: pulse;
            animation-duration: 1.6s;
            animation-iteration-count: infinite;
        }

    }
}