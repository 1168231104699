@import '../../../../Utils/styles/colors';
.createAutomationModal{
    width: 700px;
    //font-family: Heebo;
    .messagingTypeWrapper{
        width: 80%;
    }
    .selectBoxes{
        padding-top: 40px;
        width: 80%;
        .nameAndDescriptionInputs{
            .nameInput{
                margin-bottom: 30px;
            }
            .descriptionInput{
                margin-bottom: 30px;
            }
        }
        .select-box{
            margin-bottom: 30px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            .MuiFormControl-root{
                width: 100%;
            }

        }
        .selectCourseAndOrg{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .selectOrg{
                width: 49%;
            }
            .selectCourse {
                width: 49%;
            }
        }
        .selectHourAndDaysBefore{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .selectHour{
                width: 49%;
            }
            .daysBeforeInput{
                width: 49%;
                //input::-webkit-outer-spin-button,
                //input::-webkit-inner-spin-button {
                //    -webkit-appearance: none;
                //    margin: 0;
                //}
                //
                ///* Firefox */
                //input[type=number] {
                //    -moz-appearance: textfield;
                //}
                input{
                    width: 100%;
                }
            }
        }

    }

    .smsTitle{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        margin-top: 12px;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 16px;
    }

}
.alertReminderTitle{
    display: flex;
    flex-direction: row;
    justify-content: center;
    img{
        width: 30px;
        margin-right: 20px;
    }


}