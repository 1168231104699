@import '../../../Utils/styles/colors';
.modalWrapper{
    display: flex;
    flex-direction: column;
    font-family: Heebo, OpenSansCond;
    height: 100%;
    .modalTitle{
        font-size: 26px;
        font-weight: bold;
        border-bottom: 1px solid black;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .modalContent{
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        min-height: 0;
        overflow-y: auto;
        padding: 0 20px 0 20px;
        margin: 20px 0 20px 0;
    }
    .modalFooter{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        height: 60px;
        min-height: 60px;
        border-top: 1px solid black;
        button{
            margin-right: 12px;
        }
    }
    .horizontalDivider {
        //width: calc(100% - 60px);
        margin: 30px 0 30px 0;
        height: 1px;
        background-color: $subTitle;
    }

}

.modalAbsoluteWrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 90%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
}
.MuiBox-root{
    border: 5px solid $menu-button;
    border-radius: 26px !important;
    max-height: 100%;
}
.ModalBox{
    position: relative;
    height: 100%;
}

.ModalX{
    position: absolute;
    top: 6px;
    left: 6px;
    cursor: pointer;
    color: $menu-button;

}

.MuiModal-root{
    height: 100%;
    width: 100%;
}


