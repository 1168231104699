@import '../../../../Utils/styles/colors';
.sendSMSModal{
    width: 700px;
    //font-family: Heebo;
    .selectBoxes{
        padding-top: 40px;
        width: 80%;
        .MuiFormControl-root{
            width: 100%;
        }
        .select-box{
            margin-bottom: 30px;
            display: flex;
            flex-direction: row;


        }
        .selectCourseAndOrg{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            //align-items: center;
            select{
                min-width: 200px;
                width: 200px;
            }

        }
    }


    .smsTitle{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        margin-top: 12px;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 16px;
    }
}
.alertReminderTitle{
    display: flex;
    flex-direction: row;
    justify-content: center;
    img{
        width: 30px;
        margin-right: 20px;
    }


}