@import "../../../Utils/styles/colors.scss";
.TypeVerificationCode{
    width: 100%;
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    input{
        width: 100%;
    }
    .sendAgainAndTextWrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 6px 0 6px;
        .tryAgain{
            font-size: 14px;
            color: $color-link;
            cursor: pointer;
        }
        .messageSentText{

        }
    }


}