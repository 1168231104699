.SignDocPage {
    .docAndSignatureWrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 12px;
        height: 100%;
        .signDocSectionWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 50%;
            .canvaEmbedWrapper{
                width: 100%;
                height: 100%;
            }
            &.fullWidth{
                width: 100%;
                .signDocWrapper{
                    &.watch{
                        width: 50%;
                    }
                }
            }
            .signDocWrapper {
                text-align: center;
                width: 100%;
                flex-grow: 1;
                max-height: 100%;
                overflow-y: hidden;
            }
        }
        .signatureWrapper{
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            .signature-canvas{
                border: 1px solid #c4c4c4;
            }
            .signatureSubtitle{
                margin-bottom: 12px;
                font-size: 18px
            }
            .signature{
                margin-bottom: 12px;
                .signature-canvas{
                    width: 400px;
                    height: 200px;
                }
            }
            .docButtonsWrapper{
                width: 400px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                button{
                    margin-right: 12px;
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .SignDocPage {
        .docAndSignatureWrapper{
            flex-direction: column;
            align-items: center;
            height: unset;
            .signDocSectionWrapper {
                width: 94%;
                .signDocWrapper {
                    width: 100%;
                    height: 420px;
                }
            }
            .signatureWrapper{
                width: 94%;
                height: 100%;
                margin-top: 20px;
                padding-bottom: 40px;
                .signatureSubtitle{

                }
                .signature{
                    .signature-canvas{
                        width: 100%;
                        height: 200px;
                    }
                }
                .docButtonsWrapper{
                    width: 100%;
                }
            }
        }
    }
}