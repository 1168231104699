@import '../../Utils/styles/colors';


.UserIconMenu {
  display: flex;
  align-items: center;
  .selectBoxWrapper {
    border-radius: 6px;
    background-color: $themeLight;
  }
  .languageIconButtonWrapper{
    font-size: 17px;
    font-weight: 700;
    color: $themeLight;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .MuiSvgIcon-root{
        color: $themeLight;
    }
  }
}


