.GeneralSettings{
    padding-top: 20px;
    .ButtonsRow{
        display: flex;
        justify-content: center;
        margin-top: 20px;

    }
    button{
        width: 300px;
        height: 60px;
        margin-left: 12px;
        &:last-of-type{
            margin-left: 0;
        }
    }
}