@import '../../../../Utils/styles/colors.scss';
.courseImages{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 12px;
    .courseImage{
        border-radius: 50%;
        width: 80px;
        height: 80px;
        margin-left: 10px;
        margin-bottom: 10px;
        overflow: hidden;
        cursor: pointer;
        &.selected{
            width: 78px;
            height: 78px;
            border: 4px solid $GraphColor1;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}