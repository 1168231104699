@import "../../../Utils/styles/colors";
.dropzone {
    width: 100%;
    height: 100%;
    cursor: pointer;
    &.file-upload{
        border: 1px dashed #878787;
        cursor: pointer;
        border-radius: 4px;
        padding: 2px;
        overflow: hidden;
        user-select: none;
    }
    &.accepted{
        background-color: $color-success;
        .file-upload-messages{
            margin-top: 20px;
            .file-upload-description{
                color: $themeLight;
            }
        }
    }
    &.dragOver{
        background-color: $color-light-blue;
    }
    .file-upload-messages{
        margin-top: 20px;
        .file-upload-description{
            color: $subTitle;
        }
    }

    .progressOrChild{
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}