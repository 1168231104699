@import '../../../../Utils/styles/colors';
.NotificationSuccessAlertMessageBody{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    font-size: 18px;
    .messagesCount{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
    .infoMessage{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        margin-top: 10px;
        color: $greyText;

    }

}