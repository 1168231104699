.SADeleteUsersModal{
    width: 700px;
    .message{
        font-size: 18px;
        font-weight: bold;
        text-align: right;
        width: 100%;
        margin-bottom: 20px;
    }
    .usersToDelete{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        width: 100%;
        .userToDelete, .titleRow{
            display: flex;
            width: 100%;
            margin-bottom: 10px;
            .userDataCell{
                width: 100px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                text-align: right;
            }
        }
        .titleRow{
            font-weight: bold;
        }
    }
}