
.SuperAdminCustomerMgmnt{
    width: 100%;
    height: 100%;
    .superAdminCustomersTableWrapper{
        flex-grow: 1;
        width: 100%;
        min-height: 0;
    }
    .buttonsWrapper{
        margin-bottom: 20px;
    }
}