@import '../../../../Utils/styles/colors';



.statusCol{
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .statusInCol{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        border-radius: 5px;

    }
    .statusCol-token{
        display: flex;
        align-items: center;
        justify-content: center;
        //border:  1px solid grey;
        border-radius: 10px;
        height: 30px;
        color: black;
        background-color: transparentize(grey, 0.8);
        width: 90px;
        min-width: 90px;
        max-width: 90px;
    }
    &.SCHEDULED{
        .statusInCol{
        }
    }
    &.COMPLETED{
        .statusCol-token{
            background-color: $color-success;
            color: white;
        }
    }
}