.logoUploadWrapper{
    width: 400px;
    .dropzoneWrapper{
        height: 200px;
        width: 200px;
    }
    .notification-logo{
        margin-top: 12px;
        color: red;
    }
}