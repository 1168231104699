@import '../../../Utils/styles/colors';

.editNotesModal {
    position: relative;
    width: 900px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-left: 22px;
    .editNotesContent{
        align-items: flex-start;
    }
    .createNoteButton{
        margin-right: 20px;
    }
    .notesAndOrgsWrapper{
        width: 100%;
    }
}

.horizontalDivider {
    //width: calc(100% - 60px);
    margin: 30px 30px 30px 0;
    height: 1px;
    background-color: $subTitle;
}