@import "../../../Utils/styles/colors.scss";
.SuperAdminEditCustomer{
    width: 900px;
    .detailsTitle, .userCreationTitle{
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 20px;
    }
    .detailsRow{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 10px;
        .MuiFormControl-root{
            margin-left: 12px;
            &:last-child{
                margin-left: 0;
            }
        }
    }
    .details{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

    }
    .userCreation{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        position: relative;
        .MuiFormControl-root{
            &:last-child{
                margin-left: 0;
            }
        }
        .ltrInput{
            input{
                direction: ltr;
            }
        }

        .userIdentifierWithPrefix{
            width: 44%;
            min-width: 44%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-left: 12px;
            .MuiFormControl-root{
                margin-left: 0;
            }
            .inputPrefix{
                direction: ltr;
                height: 56px;
                //min-width: 80px;
                border-top: 1px solid black;
                border-left: 1px solid black;
                border-bottom: 1px solid black;
                padding-left: 8px;
                padding-right: 8px;
                border-radius: 6px 0 0 6px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-weight: bold;
            }
        }

    }
    .organizationsTable{
        flex-grow: 1;
    }
    .userNameNote{
        color: red;
    }
}