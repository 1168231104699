@import '../../../Utils/styles/colors';
.SendNotificationCounts{
    .smsStatusInformation{
        margin-top: 40px;
        text-align: center;
        font-size: 22px;
        .statusTitle{
            font-weight: 600;
            margin-bottom: 6px;

        }
        .statusData{
            margin-bottom: 6px;
        }
        .status-bold {
            font-weight: 600;
            margin-left: 8px;
        }
    }
    .statusError{
        color: $color-red;
        font-size: 20px;
        text-align: center;
        margin-top: 26px;
        .errorBold{
            font-weight: 600;
        }
    }
}